<template>
  <div
    class="task-container text-center"
  >
    <h1 class="text-h4 white--text mb-1">Stay Productive</h1>
    <h2 class="text-h6 white--text mb-10">
      Create a new task and stay focused!
    </h2>
    <v-btn class="mainButton rounded-pill" @click="$emit('toTaskList')">
      + New Task
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'NewTask',
}
</script>

<style lang="scss" scoped>
.task-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
