<template>
  <v-card
    class="mx-auto task-list"
    color="transparent flexcard"
    max-width="500"
    outlined
    tile
    dark
  >
    <h2 v-if="tasks.length" class="text-h4 text-center mb-5 mt-10">
      Task List
    </h2>
    <v-list color="transparent" flat>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="task in tasks"
          :to="task.edit ? '' : {
            name: 'Pomodorus',
            params: { task: task.title.trim().toLowerCase().split(' ').join('-') },
            query: {id: task.id}
          }"
          :key="task.id"
          class="mb-4"
          color="red"
        >
          <v-list-item-icon
            class="align-self-center"
          >
            <v-icon large color="#DB2315">alarm</v-icon>
          </v-list-item-icon>
          <v-text-field
            v-if="task.edit"
            v-model="task.title"
            @click.prevent=""
            @keypress.enter="task.title === '' ? '' : $emit('editTask', task.id)"
            color="white"
            autofocus
          >
          </v-text-field>
          <v-list-item-content v-if="!task.edit" class="pl-0 pr-0">
            <v-list-item-title class="white--text">{{
              task.title
            }}</v-list-item-title>
            <v-list-item-subtitle class="total-time mt-1">
              {{task.wTime ? 'Worked time: ' + $options.filters.toHowers(task.wTime) : 'Click to start!'}}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-btn
            @click.prevent="$emit('editTask', task.id)"
            :disabled="task.title === ''"
            icon
          >
            <v-icon color="#FB8A82">{{
              task.edit ? "mdi-check-outline" : "mdi-pencil-outline"
            }}</v-icon>
          </v-btn>
          <v-btn 
            icon 
            @click.prevent="$emit('deleteTask', task.id)"
          >
            <v-icon color="#F14336">delete</v-icon>
          </v-btn>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div class="add-task-btn text-center mt-3">
      <v-btn
        :disabled="disbaleButton"
        @click="$emit('addNewTask')"
        class="mainButton rounded-pill mb-3"
      >
        + New Task
      </v-btn>
    </div>
  </v-card>
</template>


<script>
export default {
  name: 'TaskList',
  props: ['tasks', 'disbaleButton'],
}
</script>


<style lang="scss" scoped>
.v-list-item {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  color: #fff;
  &__content {
    position: relative;
    padding: 20px;
    span {
      position: absolute;
      bottom: 2px;
      z-index: 9;
      font-size: 11px;
      color: rgb(255 255 255 / 70%);
    }
  }
}

.v-list-item--link:before {
  border-radius: 20px;
}

input {
  color: #ffff !important;
}

.add-task-btn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.no-tasks {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.task-list.v-sheet.v-card {
  position: relative;
  height: 100%;
}

.total-time {
  font-size: 0.69rem;
  font-weight: 300;
}

.v-list-item__content {
  padding: 10px;
}

.v-application--is-ltr .v-list-item__action:first-child, 
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 20px;
}
</style>
