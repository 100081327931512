<template>
  <v-row>
    <v-col class="pt-0 pb-0">
      <new-task
        v-if="checkTasks"
        @toTaskList="clickToTaskList"    
      ></new-task>
      
      <tasks-list
        v-else
        :tasks="tasks"
        :disbaleButton="disbaleButton"
        @editTask="editTask"
        @deleteTask="deleteTask"
        @addNewTask="addNewTask"
      ></tasks-list>
    </v-col>
  </v-row>
</template>

<script>
import NewTask from '../components/NewTask.vue';
import TasksList from '../components/TasksList.vue';

export default {
  name: "Task",
  data() {
    return {
      tasks: [],
      toTasks: true,
      loader: true
    };
  },
  components: {
    NewTask,
    TasksList
  },
  methods: {
    clickToTaskList() {
      this.toTasks = false;
      this.tasks.push({
        id: new Date().getTime(),
        title: "Add your first task here!",
        edit: true,
      });
    },
    addNewTask() {
      this.tasks.push({
        id: new Date().getTime(),
        title: "",
        edit: true,
      });
    },
    editTask(id) {
      let task = this.tasks.filter((task) => task.id === id)[0];
      let editStatus = (task.edit = !task.edit);
      if (editStatus === false) {
        localStorage.setItem("tasks", JSON.stringify(this.tasks));
      }
    },
    deleteTask(id) {
      this.tasks = this.tasks.filter((task) => task.id !== id);
      localStorage.setItem("tasks", JSON.stringify(this.tasks));
      localStorage.removeItem(id);
    }
  },
  computed: {
    disbaleButton() {
      let disbaleButton = false;
      this.tasks.forEach((task) => (task.edit ? (disbaleButton = true) : ""));
      return disbaleButton;
    },
    checkTasks() {
      return this.tasks.length == 0 ? true : "";
    }
  },
  async mounted() {
    const data = await localStorage.getItem("tasks");
    data ? (this.tasks = JSON.parse(data)) : null;
    if(this.tasks != null) {
      this.tasks.forEach((element) => {
          element.wTime = localStorage[element.id]
      });
    }
  }
};
</script>


<style lang="scss" scoped>

</style>



